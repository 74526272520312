<template>
  <div>
    <el-menu
      :default-active="
        this.$route.path
          .substring($route.path.indexOf('/', $route.path.indexOf('/', 1) + 1))
          .indexOf('/', 1) == -1
          ? $route.path
          : this.$route.path.substring(0, $route.path.lastIndexOf('/'))
      "
      mode="horizontal"
      text-color="#8492a6"
      router
    >
      <el-menu-item
        :index="item.path"
        v-for="(item, index) in menu"
        :key="index"
      >
        {{ item.name }}
      </el-menu-item>

      <div class="market-state" v-if="listName == 'market'">
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="marketingFeature"
          @change="initMarketingFeature('change')"
        >
        </el-switch>
        <span style="margin-left: 10px;"
          >{{ marketingFeature ? "已" : "未" }}开启营销</span
        >
      </div>
    </el-menu>
  </div>
</template>

<script>
import menuData from "./menu-data";
export default {
  props: {
    listName: {
      type: String,
      default: "",
    },
  },

  mounted() {
    // console.log(this.$route);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    let store = menuData[this.listName];
    // console.log(store);
    if (accountInfo.authType == "M") {
      store = store.filter((event) => {
        return event.head != 1;
      });
    }
    this.menu = store;

    this.initMarketingFeature();
  },

  data() {
    return {
      menu: [], // menuData[this.listName]
      marketingFeature: false,
    };
  },

  methods: {
    initMarketingFeature(type = "init") {
      let systemData = JSON.parse(window.sessionStorage.getItem("systemData"));
      this.marketingFeature = systemData.feature.MarketingFeature;
      if (type == "change" && !this.marketingFeature) {
        this.$alert(
          "营销功能暂不支持用户手动开启，请联系帝峰客服人员",
          "提示",
          {
            confirmButtonText: "确定",
          }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  .flex-row;
  align-items: unset;
  height: 39px;
  width: 100%;

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    transition: none;
    box-sizing: border-box;
  }

  .market-state {
    .flex-row;
    justify-content: end;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .is-active {
    color: #20a0ff !important;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .el-menu-item:hover {
    background-color: transparent;
    color: #20a0ff !important;
  }
}
</style>
