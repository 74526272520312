<template>
  <div>
    <div>
      <MenuThird :listName="'card'"></MenuThird>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import MenuThird from "../../../components/menu/menu-third.vue";
export default {
  components: { MenuThird },

  data() {
    return {
      cardType: "储值卡",
    };
  },

  methods: {
    onChange() {},
    onSearch() {},
    onDialog() {},
  },
};
</script>

<style lang="less" scoped>
</style>
